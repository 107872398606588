
.divider {
  border-bottom: 2px;
  border-style: double;
  width: 100%;
}

.box {
  text-align: center;
  position: relative;
  line-height: 100px;
  background: #fff;
  height: 4px;
  width: 100%;
}

.box0:after {
  background: linear-gradient(to right, #bcbcbc 25%,#ffcd02 25%, #ffcd02 50%, #e84f47 50%, #e84f47 75%, #65c1ac 75%);
  position: absolute;
  content: '';
  height: 4px;
  right: 0;
  left: 0;
  top: 0;
}

.box1:after {
  background: linear-gradient(to right, #df1a21, #f2622e, #f7ef06, #009749, #1e4789, #00a5df, #6d2d7b);
  position: absolute;
  content: '';
  height: 4px;
  right: 0;
  left: 0;
  top: 0;
}

.box:after {
  background: linear-gradient(to right, #315a91 15%, #1e4789 30%, #1e4789 45%, #00a5df 60%, #00a5df 75%, #6d2d7b 90%);
  position: absolute;
  content: '';
  height: 4px;
  right: 0;
  left: 0;
  top: 0;
}


.menu {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  display: inline-flex;
  text-decoration: none;
  list-style-type: none;
  font-size: 15px;
  padding-left: 0px;
}

.menu > li{
  border-left: #009749;
  border-style: solid;
  border-top: none;
  border-bottom: none;
  border-right: none;
  padding-left: 5px;
  padding-right: 5px;  
  vertical-align: middle;
  text-align: center;
}

.menu > li:first-child{
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.color1 {
  color: #df1a21;
  color: #f2622e;
  color: #f7ef06;
  color: #009749;
  color: #1e4789;
  color: #00a5df;
  color: #6d2d7b;
}

.color-red {
  color: #df1a21;
}

.color-orange {
  color: #f2622e;
}

.color-yellow {
  color: #f7ef06;
}

.color-green {
  color: #009749;
}

.color-blue {
  color: #1e4789;
}

.color-blue-light {
  color: #00a5df;
}

.color-purple {
  color: #6d2d7b;
}
