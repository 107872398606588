body * {
  font-family: Gruppo, Ubuntu ,Roboto, 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-indicators [data-bs-target] {
  width: 20px!important;
  height: 20px!important;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  opacity: .5;
  transition: opacity .6s ease;
}

.vh-align {
  display: flex;
  align-self: center;
  align-items: center;
}

.v-align {
  vertical-align: auto;
  align-self: flex-start;
  padding-top: 100px;
  height: 90%;
}

.vertical-middle h1 h2 h3 p {
  color: #FFF;
}

/* Menu Personalization */
/* Translation DropDown */
.dropdown-menu[data-bs-popper] {
  left: -130%!important;
  min-width: auto;
}

.z-index-negative {
  z-index: -1;
}
/* WEBSITE RESOLUTIONS STANDARD */
/* 
For Mobile devices: 320px-480px
For Tablets or iPad: 480px - 768px
For Laptop or small-size screen: 768px -1024px
For Desktop or large-size screen: 1024px -1200px
For Extra-large size device: 1200px and more

start 1265
--bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px;
}

Name	Type	Default	Description
bsPrefix	
string
'col'	Change the underlying component CSS base class name and modifier class names prefix. This is an escape hatch for working with heavily customized bootstrap css.
as	
elementType
You can use a custom element type for this component.
xs	
boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
The number of columns to span on extra small devices (<576px)
sm	
boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
The number of columns to span on small devices (≥576px)
md	
boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
The number of columns to span on medium devices (≥768px)
lg	
boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
The number of columns to span on large devices (≥992px)
xl	
boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
The number of columns to span on extra large devices (≥1200px)
xxl	
boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number }
The number of columns to span on extra extra large devices (≥1400px)

*/