/* .align-center {
    top: 50%!important;
} */

section {
    height: 100vh;
}

/* Coming Soon adjusts Mobile */

.border-gradient {
    border: 20vh solid;
    border-image-slice: 1;
    /* border-width: 5px; */
  }

.border-gradient-purple {
    /* border-image-source: linear-gradient(to right, #49217f,#601b82, #7a1481, #7d1483, #8f0e85); */
    border-image-source: linear-gradient(to right, #492181, #601b82, #7d1483, #8f0e85, #8f0e85);    
    border-left: 0;
    border-right: 0;
    border-top: 0;    
    /* border-top-width: 20vh;     */
    /* Border Glowing Effect */
    /* https://codepen.io/liyrofx/pen/poVZeEG     */
}

@media (max-width: 576px) {
    .countdown {
        margin: 15px 0;
    }
}

@media (max-width: 767px) {
    .countdown {
        padding: 25px 0;
    }
}

@media (max-width: 992px) {
    .fullscreen-banner .align-center {
        top: 20px;
        height: 100vh !important;
    }

}

@media (max-width: 1200px) {
    .logo img {
        height: 85px;
    }
}

.whatsapp-icon {
    vertical-align: bottom;
}