@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Flow+Rounded&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amarante&display=swap');


/* HOME SECTION */
/* Top Configuration */
.topPage {
  margin-top: 90px;
}

.App {
  /* font-family: 'Flow Rounded',sans-serif; */
  text-align: center;
}

#scene {
  width: 200px;
}

/* Carousel Configuration */
.carousel-caption {
  right: 7.5% !important;
  left: 7.5% !important;
}

.carousel-caption-vh-middle {
  top: 50% !important;
  transform: translateY(-50%);
}

.carousel-caption-vh-bottom {
  bottom: 4.0rem !important;
}

.carousel-control-next,
.carousel-control-prev {
  width: 7.5% !important;
}

/* Carousel Configuration */

/* Dimensions Configuration */
.w-100 {
  width: 100% !important;
}

.w-95 {
  width: 95% !important;
}

.w-90 {
  width: 90% !important;
}

.w-85 {
  width: 85% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 75% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-60 {
  width: 60% !important;
}

.w-55 {
  width: 55% !important;
}

.w-50 {
  width: 50% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-35 {
  width: 35% !important;
}

.w-30 {
  width: 30% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.w-5 {
  width: 5% !important;
}

/* Dimensions Configuration */

/* Font-Configuration */

/* Font-Configuration */

.col-img-left {
  display: flex;
  justify-content: left;
}

.col-img-right {
  display: flex;
  justify-content: right;
}


.container-first-session {
  height: 60vh;
  /* margin-top: 40px; */
}

.vertical-middle h1 h2 h3 p {
  color: #FFF;
}

.title {
  font-weight: bold;
}

.block-message {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  font-size: 1.5em;
  text-align: left;
  font-weight: 300;
}

.block-message-title-3>h1 {
  /* color: #008cba; 8f0e84 soft - 75056c darker */
  color: #FFF;
  font-size: 48px;
  /* font-family: 'Flow Rounded', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.block-message-title-2>h1 {
  /* color: #008cba; 8f0e84 soft - 75056c darker, light dd77d5 */
  color: #fff;
  font-size: 48px;
  /* font-family: 'Flow Rounded', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.block-message-title-1>h1 {
  /* color: #008cba; */
  color: #53ECFE;
  font-size: 48px;
  /* font-family: 'Flow Rounded', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.block-message-title-1>h2 {
  color: #fff;
  font-size: 25px;
  /* font-family: 'Flow Rounded', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: 600;
}

.block-message-title>p {
  color: #fff;
  font-size: 18px;
  /* font-family: 'Flow Rounded', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.block-message-white>h2,
.block-message-white>p {
  color: #FFF;
}

a .nav-link {
  text-transform: uppercase;
}

/* New Resolutions Adjustment */

@media screen and (min-height: 1280px) and (max-width: 1440px) {
  .carousel-caption {
    bottom: initial;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}


@media screen and (max-width: 575.98px) {
  /* Styles for extra small devices (phones in portrait) */
  .block-message-title-2>h1 {
    color: #fff;
    font-size: 25px!important;
    font-weight: bolder!important;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  /* Styles for small devices (phones in landscape) */
  .block-message-title-2>h1 {
    color: #fff;
    font-size: 25px!important;
    font-weight: bolder!important;
  }  
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  /* Styles for medium devices (tablets) */
  .block-message-title-2>h1 {
    color: #fff;
    font-size: 30px!important;
    font-weight: bolder!important;
  }  
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  /* Styles for large devices (small laptops/desktops) */
}

@media screen and (min-width: 1200px) {
  /* Styles for extra large devices (large desktops) */
}

@media screen and (min-width: 1200px) {
  /* Styles for extra large devices (large desktops) */
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  /* Styles for large devices (desktops) */
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  /* Styles for medium devices (tablets) */
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  /* Styles for small devices (phones in landscape) */
}

@media screen and (max-width: 575.98px) {
  /* Styles for extra small devices (phones in portrait) */
}




/* 


@media screen and (min-width: 576px) {
  .block-message-title-2>h1 {
    color: #fff;
    font-size: 30px!important;
    font-weight: bolder!important;
  }
}

@media screen and (min-width: 992px) {
  .block-message-title-2>h1 {
    color: #fff;
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .block-message-title-2>h1 {
    color: #fff;
    font-size: 36px;
  }
}

@media screen and (min-width: 1440px) {
  .block-message-title-2>h1 {
    color: #fff;
    font-size: 48px;
  }
} */