a .nav-link {
  text-transform: uppercase;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.navbar-toggler-icon {
  width: 1.2em !important;
  height: 1.2em !important;
}

#site-header {
  background: #FFF;
}

.navbar a {
  display: flex;
  align-items: center;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #00a5df !important;
}

@media (max-width: 992px) {
  .navbar-collapse {
    margin-top: 54px;
  }
}

.navbar-toggler {
  padding: 0!important;
  border-radius: 9999px!important;
  height: 40px;
  width: 40px;
  /* transform: rotate(90deg);  
  transition: transform 3s ease!important;
  transition: all 0.5s ease!important;  */
}

.navbar-toggler:hover {
  background-color: #00a5df25;
  border: 1px solid #AF2BBF;
  box-shadow: 0 0 0 1px!important;
  transition: transform 0.3s ease!important;
  transform: rotate(45deg);   
}

.navbar-toggler:focus {
  background-color: #00a5df25;
  border: 1px solid #AF2BBF;
  box-shadow: 0 0 0 1px!important;
  transition: transform 0.3s ease!important;
  transform: rotate(45deg);   
}

.navbar-toggler:active {
  box-shadow: 0 0 0 1px!important;
  transition: transform 0.3s ease!important;
  transform: rotate(45deg);  
}

/* Style for the rotating icon */
.rotateIcon {
  transition: transform 0.5s ease-in-out!important;
}

/* Rotate the icon when the menu is open */
.rotateIcon .open {
  transform: rotate(45deg)!important;
  /* transition: display 10s ease-in-out; */
}