.logonc {
    height: 75%;
    width: 75%;
  }

  .logo-purple path text tspan{
    fill: #7a1481;
    stroke: #7a1481;
  }
  a .nav-link {
    text-transform: uppercase;
  }

  .logo-purple img{
    width: 200px;
   }

   .logonc {
    height: 75%;
    width: 75%;
  }
  
  .logo-purple {
    width: 15%;
  }
  
  .logo-purple path text tspan {
    fill: #7a1481;
    stroke: #7a1481;
  }


  .scrolled img {
    /* height: auto; */
    /* padding-top: 10px; */
    width: 120px;
    transition: width 0.5s ease; 
  }
  
  /* .scrolled a {
    height: auto;
    /* padding-bottom: 0px; 
    transition: height 0.5s ease; 
  }    */

  /* .scrolled .logo-purple{
    width: 10%;
    transition: width 0.5s ease; 
   } */

   @media (max-width: 992px) {
    .logo-purple {
      width: 408px;
      transition: width 0.5s ease; 
    }
   }